import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import SimpleModernHeadline from '../../components/SimpleModernHeadline/SimpleModernHeadline';
import HeadlineWithImage from '../../components/HeadlineWithImage/HeadlineWithImage';

import img_leistung_17 from '../../images/leistungen/img17.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Botox© Behandlung | Expertin in Bochum" description="Dr. K. Kostka bietet professionelle Botox© Behandlungen mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"/>
    <Section>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12}>
            <HeadlineWithImage headline={"Botox© Behandlung"} subtitle={"Übersicht über die Botox© Behandlung bei Dr. Kostka in Bochum"} frontImage={img_leistung_17}></HeadlineWithImage>
          </Col>
        </Row>
        <div>
            Wir haben eine natürliche Mimik wie Stirnrunzeln, Grübeln oder Blinzeln bei Sonneneinstrahlung. Im Laufe der Jahre führt dieser ständige Muskelzug anfangs zu Linien, später entstehen Falten.
            <br/>
            <br/>
            Mit Botox© können diese mimischen Falten der oberen Gesichtshälfte geglättet werden.
            <br/>
            <br/>
            Ich biete ihnen professionelle Botox© Behandlungen in meiner Praxis in der Bochumer Innenstadt an.
            <br />
            Mit einer sehr feinen Nadel spritze ich das Botox© in den betreffenden Gesichtsmuskel, dessen Aktivität so eingeschränkt wird: Falten verschwinden, Gesichtszüge entspannen sich und man wirkt weniger gestresst und zornig.
            
            <br/>
            <br/>
            Mein oberstes Prinzip ist der Erhalt des natürlichen Ausdruckes – durch eine zurückhaltende Dosierung.
            <br/>
            <br/>
            Machen Sie gerne einen Termin in meiner Bochumer Praxis aus, um direkt von einer Expertin für Botox© Behandlungen mehr zu erfahren. In einem persönlichen Gespräch werden wir ausführlich alle möglichen Therapieoptionen besprechen.
            <br/>
            <br/>
            Der Effekt ist nach wenigen Tagen sichtbar und hält in der Regel 3-4 Monate an. Eine Auffrischung ist danach möglich.
            <br/>
            <br/>
            <SimpleModernHeadline h3={true}>Wo kommt Botulinumtoxin zum Einsatz?</SimpleModernHeadline>
            <ul>
            <li>Zornesfalte</li>
            <li>Krähenfüße (seitliche Augenfalten)</li>
            <li>Stirnfalte</li>
            <li>Bunny line </li>
            </ul>
            <SimpleModernHeadline h3={true}>Übersicht zur Behandlung mit Botulinumtoxin:</SimpleModernHeadline>
            <ul>
            <li>Schmerzen: sehr gering </li>
            <li>Gesellschaftsfähig: in der Regel sofort</li>
            <li>Sichtbares Ergebnis: nach 14 Tagen</li>
            <li>Wirkdauer: 4-6 Monate</li>
            </ul>
        </div>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
